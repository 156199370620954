.logo-size {
  width: 150px;
}

.nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 500 !important;
  transition: all 0.3s !important;
  position: relative;
}

nav .nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 3px;
  background-color: #3B885A;
  bottom: 2px;
  left: 0;
  border-radius: 100px;
  transition: width 0.3s;
}

nav .nav-link:hover::after {
  width: 100%;
}

.decoration-footer {
  text-decoration-color: #fff;
}

.navbar-nav .nav-link.active::after {
  width: 100%;
}

nav .navbar-nav .nav-link.active,
nav .navbar-nav .nav-link.show {
  color: #3B885A !important;
}

nav.bg-body-tertiary {
  background-color: #F8F0DD !important;
}

nav .nav-link:hover {
  color: #3B885A !important;
}

ul.nav-icons li i {
  font-size: 20px;
  color: #3B885A;
}

.cursor-pointer {
  cursor: pointer;
}

.navbar-toggler:focus {
  box-shadow: unset !important;
}

.navbar-toggler:focus .icon span:nth-child(2) {
  width: 100%;
}

nav button .icon {
  width: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

nav button .icon span {
  background-color: #3B885A;
  margin-bottom: 5px;
  height: 2px;
}

nav button .icon:hover span:nth-child(2) {
  width: 100%;
}

nav button .icon span:first-child {
  width: 100%;
}

nav button .icon span:nth-child(2) {
  width: 60%;
  transition: width 0.3s;
}

nav button .icon span:last-child {
  width: 100%;
}

.offcanvas.offcanvas-start {
  width: 300px !important;
}



.header-content h2 {
  font-family: "Prata", serif;
  color: #fff;
  font-size: 5rem;
}

.header-content p {
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-size: 1.3rem;
}

.swiper-pagination-bullet {
  background-color: #291900 !important;
  width: 15px !important;
  height: 15px !important;
}

.swiper-pagination-bullet-active {
  background-color: #291900 !important;
}

.running-text {
  background-color: #794600;
}

.running-text p {
  text-transform: uppercase;
  white-space: nowrap;
  animation: move-rtl 10s linear infinite;
}

.our-products h3 {
  font-family: "Cairo", sans-serif;
  font-size: 40px;
  color: transparent;
  font-weight: 600;
  -webkit-text-stroke-width: 0.8px;
  -webkit-text-stroke-color: black;
  position: relative;
  display: inline;
  cursor: default;
}

.our-products h3:hover::after {
  width: 100%;
}

.our-products h3::after {
  content: '';
  position: absolute;
  height: 3px;
  width: 50%;
  background-color: #000;
  bottom: 5px;
  left: 0;
  transition: width 0.5s;
}

.main-title h2 {
  color: #3B885A;
  font-family: "Prata", serif;
  font-size: 3rem;
}

.section-info .row .swiper {
  padding: 50px 40px !important;
}

.product {
  border: 1.5px solid #DFECC2;
  border-radius: 8px;
  transition: 0.3s;
  height: 680px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.product .product-heart {
  color: #3B885A;
  opacity: 0;
  transition: all 0.3s;
  cursor: pointer;
}

.product:hover .product-heart {
  opacity: 1;
}

.product:hover {
  transform: scale(1.05);
  box-shadow: inset 0px 0px 50px 20px #DFECC2;
}

.product .product-price {
  background-color: #DFECC2;
  border-radius: 0 0 20px 0;
  opacity: 0;
  transition: 0.3s;
}

.offer {
  border: 1.5px solid #DFECC2;
  border-radius: 8px;
  overflow: hidden;
}

.offer .product-offer {
  background-color: #DFECC2;
  border-radius: 0 0 20px 0;
}

.offer .product-offer p {
  font-family: "Cairo", sans-serif;
  color: #4d3206;
  font-weight: 600;
  font-size: 20px;
  font-weight: 500;
}

.offer-cart i {
  color: #3B885A;
}

.offer-cart {
  background-color: #f8f0dd;
  padding: 5px;
  border-radius: 5px;
}

.offer:hover .offer-info {
  background-color: #3b885a;
}

.offer-info {
  transition: 0.3s;
}

.offer:hover .offer-rate {
  opacity: 1;
}

.offer:hover .offer-info h5,
.offer:hover .offer-info p {
  color: #DFECC2;
}

.offer-rate {
  color: #EFC019;
  opacity: 0;
  transition: 0.3s;
}

.product:hover .product-price {
  opacity: 1;
}

.product .product-price p {
  font-family: "Cairo", sans-serif;
  color: #4d3206;
  font-weight: 600;
}


.product h4 {
  color: #3B885A;
  font-size: 1.75rem;
  margin-top: 20px;
  transition: all 0.3s;
}

.product p {
  color: #3B885A;
  font-size: 1.125rem;
}

.product button {
  width: 12.5rem;
  height: 2.5rem;
  background-color: #3B885A;
  outline: none;
  border: 1px solid #3B885A;
  border-radius: 5px;
  position: relative;
  transition: all 0.2s;
}

.product button p {
  color: #DFECC2;
}

.product button:hover {
  background-color: #fff;
}

.product button:hover p {
  color: #3B885A;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0px !important;
}

.slick-dots {
  bottom: 20px !important;
}

.slick-dots li button:before {
  font-size: 20px !important;
  color: #fff !important;
  opacity: 1 !important;
}

.slick-dots li.slick-active button:before {
  color: #291900 !important;
}

.viwe-all-btn button {
  height: 3.5rem;
  width: 13.5rem;
  border: 1px solid #3B885A;
  border-radius: 8px;
  background-color: #3B885A;
  font-weight: 500;
  font-size: 1.125rem;
  color: #DFECC2;
  transition: 0.2s;
}

.full button {
  width: 100%;
}

.viwe-all-btn button:hover {
  background-color: transparent;
  color: #3B885A;
}

.main-color {
  color: #3B885A;
}

.main-font {
  font-family: "Poppins", sans-serif;
}

.moisturizers-title h3 {
  font-weight: 500;
  font-size: 3rem;
}

.moisturizers-title p {
  font-size: 2rem;
}

.moisturizers-min-title h5 {
  font-size: 20px;
}

.moisturizers-min-title p {
  font-size: 16px;
}

.stars-group i {
  color: #EFC019;
  font-size: 18px;
}

.form-select:focus {
  border-color: #3B885A !important;
  outline: 0 !important;
  box-shadow: 0 0 0 .25rem rgb(228 167 4 / 0%) !important;
}

.moisturizers-min-price p {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #AC7326;
}
.product-img{
  width: 280px;
  margin: auto;
}
.product-img img {
  width: 100% !important;
  height: 100%;
}

.offer-img img {
  width: 100% !important;
  height: 100%;
}

.nav.nav-tabs li button {
  font-size: 1.2rem;
}

/* new */
.baby-care-banner,
.moisturizers-img {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Optional, if you also want to vertically center */


}

.moisturizers-img img {
  width: 100%;
  height: 100%;
}

/* ----------- */

.baby-product-img {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border: 1px solid #dddd;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}


.baby-product-img img {
  width: 100%;
  height: 100%;
}

.baby-products .swiper {
  border-radius: 20px;
  margin-top: -150px;
  background-color: #fff;
  padding: 20px 0;
  box-shadow: 0px 0px 20px #00000077;
}

.baby-products .swiper .swiper-button-disabled {
  opacity: 0;
}

.baby-products .swiper .swiper-button-prev,
.baby-products .swiper .swiper-button-next {
  color: #000;
}

.baby-products p {
  font-size: 20px;
}

.product-details-content {
  border: 1px solid #ddd;
  border-radius: 10px;
}

.product-details-content h3 {
  color: #3B885A;
  font-size: 2.75rem;
}

.toners-content {
  border: 1px solid #ddd;
  border-radius: 10px;
}

.toners-info h3 {
  color: #3B885A;
  font-size: 2.75rem;
}

.toners-info p {
  font-size: 1.5rem;
  color: #706D6E;
}

.eye-care h3 {
  font-size: 2.5rem;
}

.eye-care-title h5 {
  font-size: 20px;
}

.eye-care-title p {
  font-size: 16px;
}

.our-story-text h2 {
  font-family: "Prata", serif;
  font-size: 3rem;
}

.our-story-text p {
  font-size: 1.125rem;
  font-family: "Poppins", sans-serif;
  line-height: 26px;
}

.our-story::after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 30%;
  bottom: 0;
  right: 0;
  z-index: -1;
  background-image: url(./assets/images/Vector.png);
  background-size: cover;
}

.our-story::before {
  content: '';
  position: absolute;
  height: 17%;
  width: 50%;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-image: url(./assets/images/Vector2.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none;
  color: #664A20 !important;
}

.products .nav-tabs .nav-link.active::after {
  width: 100%;
}

.nav-tabs .nav-link {
  border: none !important;
}

.products .nav-link {
  color: #000;
}

.products .nav-tabs .nav-link {
  position: relative;
}

.products .nav-link:hover {
  color: #664A20;
}

.nav-tabs .nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 3px;
  background-color: #664A20;
  bottom: 2px;
  left: 0;
  border-radius: 100px;
  transition: width 0.3s;
}

.offer .offer-info h5 {
  font-size: 1.5rem;
  color: #291900;
  font-family: "Poppins", sans-serif;
}

.offer .offer-price {
  font-size: 1.25rem;
  color: #AC7326;
  font-family: "Poppins", sans-serif;
}

.product-details-content .min-title {
  font-size: 20px;
  color: #000000c2;
}

.details-price .new-price {
  font-size: 20px;
  color: #CE3C0F;
}

.details-price .old-price {
  font-size: 20px;
  color: #0000008f;
  text-decoration: line-through;
}

.product-details-content .details-desc {
  font-size: 14px;
  line-height: 17px;
  color: #706D6E;
}

.details-footer .share {
  color: #3B885A;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
}

.details-footer .returns {
  font-size: 20px;
}

.details-footer .returns-title {
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
}

.cart {
  padding: 50px 0;
}

.product-cart-title,
.product-cart-price h5 {
  font-size: 20px;
  color: #17183B;
}

.product-cart-price h5.product-cart-discount {
  color: #a1a1a1;
}

.product-cart-info p:first-child {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #A2A3B1;
}

.product-cart-info p:last-child {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #17183B;
}

.count {
  width: 198px;
  height: 48px;
  padding: 12px;
  border: 1px solid #664A20;
  border-radius: 8px;
  color: #664A20;
  font-size: 20px;
}

.total-cart-price-info {
  border: #ddd 1px solid;
}

.total-cart-price-info h4 {
  font-size: 24px;
}

.total-cart-price-info div p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

.coupon-field {
  width: 100%;
  padding: 12px 50px;
  border: 1px solid #ddd;
  outline: none;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

.coupon i {
  font-size: 22px;
}

.enter-coupon-btn button {
  border: 1px solid #3B885A;
  border-radius: 8px;
  background-color: #3B885A;
  font-weight: 500;
  font-size: 1.125rem;
  color: #DFECC2;
  transition: 0.2s;
}

.enter-coupon-btn button:hover {
  background-color: transparent;
  color: #3B885A;
}

footer {
  background-color: #3B885A;
}

.footer-content p {
  font-size: 16px;
}

.footer-brdr {
  border: 1px solid #fff;
  opacity: 1;
}

.our-story-text h2 {
  font-family: "Prata", serif;
  font-size: 3rem;
  color: #291900;
}

.our-story-text p {
  font-size: 1.125rem;
  font-family: "Poppins", sans-serif;
  line-height: 26px;
  color: #291900;
}

.about {
  background-color: #DFECC2;
  z-index: 2;
}

.contact {
  background-color: #DFECC2;
}

.our-team-desc {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: #667085;
}

.team-name h5 {
  color: #664A20;
  font-weight: 500;
  font-size: 18px;
}

.team-name p {
  font-size: 16px;
}

.contact-information {
  background-color: #235236;
  color: #DFECC2;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  position: relative;
}

.contact-header h3 {
  font-size: 28px;
}

.contact-header p {
  font-size: 18px;
}

.contact-information::after {
  content: '';
  position: absolute;
  background-image: url(./assets/images/Vector3.png);
  background-size: cover;
  width: 40%;
  height: 50%;
  bottom: 0;
  right: 0;
}

.contact-footer div {
  width: 40px;
  height: 40px;
  background-color: #fff;
  color: #000;
  font-size: 25px;
}

.contact-footer div.twitter {
  color: #74c0fc;
}

.contact-text input,
.contact-text textarea {
  font-family: "Poppins", sans-serif;
  color: #A39898;
  font-family: 16px;
  padding: 10px 55px;
  outline: none;
  border-radius: 8px;
}

.contact-text i {
  font-size: 20px;
  color: #A39898;
}

.details p {
  font-size: 16px;
}

.how-to-use ul {
  list-style: decimal;
  margin-top: 15px;
}

.how-to-use ul li {
  color: #706D6E;
  font-size: 1.5rem;
}

.how-to-use p {
  font-size: 1.75rem;
  color: #3B885A;
}

.how-to-use {
  border: 1px solid #ddd;
}

.coustomer-reviews h4 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #291900;
}

.rating-stars p {
  font-size: 1.125rem;
}

.css-15befwp-MuiRating-root {
  font-size: 30px !important;
}

.progress,
.progress-stacked {
  height: 18px !important;
  flex-grow: 1;
}

.progress-bar {
  background-color: #EFC019 !important;
}

.modal-body h4 {
  font-size: 2.5rem;
}

.modal-body .css-1qqgbpl-MuiRating-root {
  font-size: 3rem !important;
}

.add-review {
  border: 1px solid #ddd;
  outline: none;
  border-radius: 8px;
  color: #A0A0A0;
  font-family: "Poppins", sans-serif;
}

.review-user-name p {
  font-size: 1.5rem;
}

.stars-status p {
  font-size: 20px;
  color: #291900;
}

.review-text {
  color: #525252;
  font-size: 16px;
  font-weight: 500;
}

.sign-text {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  outline-color: #3B885A;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.sign-up {
  border-radius: 8px;
}

.sign-up p:not(.sign-up button p) {
  font-size: 16px;
}

.sign-up h1 {
  font-size: 40px;
  font-weight: 600;
}

.payment-session .active {
  color: #3B885A;
}

.payment-session li a {
  pointer-events: none;
}

.payment-session .nav-link:focus {
  color: #3B885A !important;
}

.user-info {
  background-color: #F5F5F5;
  border-radius: 8px;
}

.user-info>p {
  font-size: 18px;
}


.address {
  border-bottom: 3px solid #ddd;
}

.not-valid {
  outline-color: #dc3545 !important;
  border-color: #dc3545 !important;
  background-color: #FBEFEF;
}

.valid {
  border-color: #3B885A !important;
  background-color: #F1F8F2;
}

.platforms-sign button img {
  width: 20px;
}

.platforms-sign button {
  outline: 0;
  border: 1px solid #3B885A;
  background-color: transparent;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
}

.sign-with-email {
  position: relative;
  color: #291900;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.sign-with-email p {
  display: inline-block;
  background-color: #fff;
  position: relative;
  z-index: 2;
  padding: 0 15px;
}

.sign-with-email::after {
  content: '';
  position: absolute;
  background-color: #EBEEF7;
  width: 50%;
  height: 2px;
  left: 0;
  top: 50%;
}

.sign-with-email::before {
  content: '';
  position: absolute;
  background-color: #EBEEF7;
  width: 50%;
  height: 2px;
  right: 0;
  top: 50%;
}

.loading {
  height: 70vh;
}

.dropdown-toggle:empty::after {
  display: none;
}

.dropdown-menu[data-bs-popper] {

}

.dropdown-menu li a,
.dropdown-menu li span {
  font-size: 16px !important;
}

.address p {
  display: inline;
  margin: 0 0 0 4px;
}

.empty h2 {
  font-family: "Prata", serif;
  font-size: 3rem;
  color: #3B885A;
}

.search {
  outline: none;
  border: 1px solid #41AEA7;
  border-radius: 8px;
  padding: 0 0px 0 8rem;
}

.search-result {
  border: 1px solid #ddd;
  height: 30vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.search-result::-webkit-scrollbar {
  background-color: #3B885A !important;
}

.search-result::-webkit-scrollbar-thumb {
  background-color: #f8f0dd !important;
}

body::-webkit-scrollbar {
  background-color: #3B885A !important;
  width: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color:
    #f8f0dd !important;
}

.search-hover {
  background-color: #ffffff !important;
}

.search-hover:hover {
  background-color: #ddd !important;
}

.modal-body .css-1qqgbpl-MuiRating-root label {
  display: inline !important;
}

.track {
  color: #667085;
  font-weight: 500;
}

.green {
  color: #12B76A;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #12B76A !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #12B76A !important;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-completed {
  color: #12B76A !important;
  font-weight: 600 !important;
}

.track-height {
  min-height: 70vh;
}

/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.loader {
  width: 220px;
  height: 20px;
  background:
    linear-gradient(#3B885A 0 0) 0/0% no-repeat #ddd;
  animation: l1 2s infinite linear;
}

.vh-80 {
  min-height: 80vh;
}

.total-cart-price-info {
  z-index: 1019 !important;
}

.google {
  box-shadow: none !important;
  border: 1px solid #3B885A !important;
  border-radius: 8px !important;
  padding: 8px 0px !important;
  font-size: 1.25rem !important;
  font-weight: 600 !important;
  color: #000 !important;
}

@keyframes l1 {
  100% {
    background-size: 100%
  }
}



@keyframes l6-0 {
  0% {
    background-position: 0 100%, 100% 100%, 100% 0
  }

  33% {
    background-position: 100% 100%, 100% 100%, 100% 0
  }

  66% {
    background-position: 100% 0, 100% 0, 100% 0
  }
}

@keyframes l6-0-0 {
  0% {
    transform: scaleX(1) rotate(0deg)
  }

  50% {
    transform: scaleX(-1) rotate(-90deg)
  }
}

@keyframes l6-1 {
  16.5% {
    transform: perspective(150px) rotateX(-90deg) rotateY(0deg) rotateX(0deg);
    filter: grayscale(0.8)
  }

  33% {
    transform: perspective(150px) rotateX(-180deg) rotateY(0deg) rotateX(0deg)
  }

  66% {
    transform: perspective(150px) rotateX(-180deg) rotateY(-180deg) rotateX(0deg)
  }

  100% {
    transform: perspective(150px) rotateX(-180deg) rotateY(-180deg) rotateX(-180deg);
    filter: grayscale(0.8)
  }
}



@keyframes move-rtl {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-500%);
  }
}



/* Target screens smaller than 768px */
@media screen and (max-width: 767.98px) {
  html {
    font-size: 10px;
  }

  .p-fix {
    margin-bottom: -20px;
  }

  .baby-products .swiper {
    margin-top: 0;
    box-shadow: unset;
  }

  .dropdown-menu[data-bs-popper] {
    left: 40px !important;
  }
}

/* Target screens between 768px and 991px */
@media screen and (min-width: 768px) and (max-width: 991.98px) {
  html {
    font-size: 14px;
  }

  .dropdown-menu[data-bs-popper] {
    left: 40px !important;
  }
}

.MuiSvgIcon-root.Mui-active circle {
  color: #ffc93f !important;
}

.MuiSvgIcon-root.Mui-completed circle {
  color: #12B76A !important;
}

.MuiRating-decimal {
  font-size: 2.5rem !important;
}
.MuiRating-decimal label{
  display: block !important;
}